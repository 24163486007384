<template>
  <v-sheet>
    <div class="text-center text-caption">{{ label }}</div>
    <div class="d-flex justify-center">
      <v-card flat class="d-flex align-content-start flex-wrap">
            <v-card flat v-if="!readonly" class="d-flex align-content-start flex-wrap">
              <v-card flat v-for="i in reversedIntervals" :key="`subtract-${i}`">

                  <app-btn :loading="loading" fab x-small class="my-1" @click="subtract(i)"
                           :disabled="!createsValidTimeCheck(i,'subtract') || disabled">
                    <v-icon>
                      {{ i > 1 ? `mdi-rewind-${i}` : `mdi-rewind` }}
                    </v-icon>
                  </app-btn>
              </v-card>
            </v-card>
          <v-card flat class="d-flex align-content-start flex-wrap">

            <div class="text-center mx-3">{{ displayedTime }}</div>
          </v-card>
          <v-card flat v-if="!readonly" class="d-flex align-content-start flex-wrap">
              <v-card flat v-for="i in intervals" :key="`add-${i}`">
                <div class="">
                  <app-btn :loading="loading" fab x-small class="my-1" @click="add(i)" :disabled="!createsValidTimeCheck(i,'add') || disabled">
                    <v-icon>
                      {{ i > 1 ? `mdi-fast-forward-${i}` : `mdi-fast-forward` }}
                    </v-icon>
                  </app-btn>
                </div>
              </v-card>
          </v-card>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import AppBtn from "@/components/app/Btn";

export default {
  name: "DurationSelector",
  props: ["readonly","loading","value", "max", "min", "disabled", "label"],
  components: {AppBtn},
  data() {
    return {
      intervals: [1, 5, 10, 30, 60],
      reversedIntervals: [60, 30, 10, 5]
    }
  },
  computed: {
    displayedTime() {
      return this.value % 60 <= 9 ? Math.floor(this.value / 60) + ':0' + this.value % 60 : Math.floor(this.value / 60) + ':' + this.value % 60
    }
  },
  methods: {
    createsValidTimeCheck(proposedInterval, method) {
      let bool = false
      switch (method) {
        case 'add':
          bool = (this.value + proposedInterval) < 1440
          break;
        case 'subtract':
          bool = 0 < (this.value - proposedInterval)
          break;
      }
      return bool
    },
    add(amount) {
      this.updateValue(this.value + amount)
    },
    subtract(amount) {
      this.updateValue(this.value - amount)
    },
    updateValue(value) {
      this.$emit("input", value)
    },
  }
}
</script>

<style scoped>

</style>