<template>
    <create-sortie-view :loading="loading"/>
</template>

<script>
import { mapState } from "vuex"
import CreateSortieView from "@/components/views/SortieView";

export default {
  name: "SortiePage",
  components: {CreateSortieView},
  computed:{
    ...mapState("app",["loading"])
  }
}
</script>

<style scoped>

</style>