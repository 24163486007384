<template>
  <v-container>
    <v-data-table
        :headers="crewListHeaders"
        :items="value"
        class="elevation-1"
        mobile-breakpoint="700"
        disable-pagination
        disable-sort
        disable-filtering
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mt-4">
          <v-toolbar-title>Crew & Passengers</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab small class="mb-2" @click="add" :disabled="disabled"><v-icon>mdi-account-plus</v-icon></v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.person="{ item }">
        <v-row>
          <v-col cols="3">
            <div class="text-end">
            <v-btn small fab color="primary" :disabled="disabled" @click="item.isPAX = !item.isPAX;setId(item)" v-if="item.role !=='Pilot in Command'">
              <v-icon v-if="item.isPAX">mdi-account-tie</v-icon>
              <v-icon v-else>mdi-seat-passenger</v-icon>
            </v-btn>
            </div>
          </v-col>
          <v-col cols="9">
            <v-autocomplete
                v-if="!item.isPAX"
                @change="setId(item)"
                v-model="item.person"
                :items="filteredPersonList"
                dense
                class="text-body-2"
                clearable
                outlined
                Label="Enter name"
                item-text="name"
                item-value="name"
                :disabled="disabled"
                :rules="[v => !!v || 'Name is required']"
            />
            <v-text-field  @change="setId(item)" dense outlined v-model="item.person" v-if="item.isPAX" :rules="[v => (v && v.length >= 5) || 'Name is required']" clearable :disabled="disabled" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.role="{ item }">
        <app-select
            v-model="item.role"
            :items="aircrewRoles"
            Label="Role"
            item-text="name"
            item-value="name"
            :rules="[value => !!value || 'Required.']"
            single-line
            :disabled="disabled"
            @input="checkSinglePIC(item);"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            class="secondary"
            fab
            small
            @click="remove(item)"
            v-if="value.length>1"
            :disabled="disabled"
        >
          <v-icon>mdi-account-minus</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AppSelect from "@/components/app/Select";

export default {
  name: "SortieCrewList",
  components: {
    AppSelect,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSortieCrewList: [],
      localPersonList: [],

      crewListHeaders: [{
        text: 'Name',
        align: 'start',
        value: 'person',
      },
        {text: 'Role', value: 'role'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],


    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("roles", ["aircrewRoles"]),
    ...mapState("person", ["persons", "loggedInPerson"]),
    ...mapState("user", ["groupMembership"]),
    totalAircraftOccupancy() {
      return this.value ? this.value.length : 1
    },
    filteredPersonList() {
      let personList = [{header: 'Members'},
        ...this.persons]
      let localSortieCrewList = this.value

      personList.forEach(function (p) {
        p.disabled = localSortieCrewList.find(c => {
          return c.person === p.name;
        });
      });
      return personList
    }
  },
  methods: {
    ...mapActions("roles", ["setAircrewRoles"]),
    ...mapActions("person", ["setPersons", "setManageNewPersonDialog"]),
    openCreatePersonDialog() {
      this.setManageNewPersonDialog(true)
    },

    setId(item) {
      let index = this.value.findIndex(i => i == item)

      console.log(`Index is ${index}`)
      console.log(`Item is ${JSON.stringify(item)}`)

      delete item.personId
      if (!item.isPAX) {
        const person = this.persons.find(p => p.name === item.person)
        console.log(`person is ${person}`)
        item.personId = person._id
      }
      this.value[index] = item

      this.$emit("input", this.value);

      console.log(this.value)
    },


    isUser(id) {
      if (id === this.loggedInUser.name) {
        return true
      } else {
        return false
      }
    },
    add() {
      this.value.push({
        person: '',
        role: '',
        isPAX: false
      })
      this.$emit("input", this.value);
      this.$forceUpdate()
    },
    remove(item) {
      const index = this.value.indexOf(item);
      this.value.splice(index, 1);
      this.$emit("input", this.value);
      this.$forceUpdate()
    },
    checkSinglePIC(item) {

      if (item.role === "Pilot in Command") {

        this.value.forEach(person => {
          const index = this.value.indexOf(person)

          if (person.role === 'Pilot in Command' && index !== item) {
            this.value[index].role = 'Passenger'

          }
        })
        this.$emit("input", this.value);
        this.$forceUpdate()
      }
    }
  },
  async mounted() {
    await this.setAircrewRoles()
    await this.setPersons(this.groupMembership[0]._id)
    if (this.value) {
      if (this.value.length == 0) {
        const person = this.persons.find(p => p._id == this.loggedInPerson._id)
        const role = this.aircrewRoles.find(r => r.name == 'Pilot in Command')

        this.localSortieCrewList.push({
          personId: person._id,
          person: person.name,
          role: role.name,
          isPAX: false
        })
      }
    }
  },
}
</script>

<style scoped>

</style>