<template>
  <v-sheet rounded="lg" class="pa-3">
    <v-skeleton-loader type="card-avatar, article, actions" v-if="loading"></v-skeleton-loader>
    <div v-if="activeSortie">
      <v-alert type="info" v-if="!scopedCanFly">Create Flight functionality Disabled. One (or more) requirements in
        order to carry out a flight with {{ getGroupName(activeSortie.group, 'name') }} is outstanding. Check Your
        notifications.
      </v-alert>
      <h1 class="my-2 subheading">{{ isReadOnly ? 'View ' : activeSortie.hasOwnProperty('_id') ? 'Edit' : 'New' }}
        Flying
        Programme
        Flight</h1>
      <v-form class="" ref="form">
        <v-container>
          <v-layout row wrap>
            <v-flex xs9 sm6 md9>
              <div class="caption">Flight made as part of</div>
              <div>
                <group-picker :items="scopedGroups" v-model="activeSortie.group" :disabled="isReadOnly || loading"
                              @change="updateGroupName"/>
              </div>
            </v-flex>
            <v-spacer/>
            <v-flex xs3 sm6 md3>
              <div class="text-body-2">-</div>
              <div class="text-body-2">
                <app-btn
                    v-if="activeSortie.hasWalked == false && activeSortie.owner === this.loggedInUser._id || activeSortie.hasWalked == false  && isSortieManager"
                    @click="isReadOnly=!isReadOnly">
                  <v-icon left>mdi-{{ isReadOnly ? 'pencil' : 'cancel' }}</v-icon>
                  {{ isReadOnly ? 'Edit' : 'Cancel edit' }}
                </app-btn>
              </div>

            </v-flex>
            <v-flex xs12 sm12 md6>
              <div class="caption">Date of Flight</div>
              <div>
                <app-date-picker v-model="activeSortie.date" :disabled="isReadOnly || loading || !scopedCanFly" :min="today"
                                 @input="validate"/>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <div class="text-body-2">Flight Type</div>
              <div>
                <v-select dense v-model="activeSortie.flightType" :items="flightTypeItems" item-text="name" :disabled="isReadOnly || loading || !scopedCanFly"
                          item-value="name" outlined label="Flight Type" :rules="[(v)=> !!v || 'Selection Required']"
                          @change="validate"/>
              </div>
            </v-flex>
            <v-flex xs12 sm9 md9>
              <div class="caption">Registration</div>
              <aircraft-picker v-model="activeSortie.registration"
                               :default="loggedInPerson.flyingData.defaultAircraft"
                               v-on:aircraft="changeTailNumber($event)"
                               :show-favourite="!isReadOnly"
                               :disabled="isReadOnly || loading || !scopedCanFly" @input="validate"/>
            </v-flex>
            <v-spacer/>
            <v-flex xs6 sm3 md3>
              <div class="caption">Type of Aircraft</div>
              <!--              <div>{{ aircraftType }}</div>-->
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm9 md9>
              <div class="caption">Departure Aerodrome</div>
              <div>
                <airport-picker label="Enter Departure Aerodrome" v-model="activeSortie.pointOfDepartureName"
                                v-on:updateAirport="updateAirportDetails($event,'departure')"
                                :default="loggedInPerson.flyingData.homeAirfield"
                                :show-favourite="!isReadOnly"
                                :disabled="isReadOnly || loading || !scopedCanFly" @input="validate"/>
              </div>
            </v-flex>
            <v-spacer/>
            <v-flex xs12 sm3 md3>
              <div class="caption">Time</div>
              <div class="text-body-2">
                <app-time-picker label="Departure Time" v-model="localEtd" :min="minTime"
                                 :disabled="isReadOnly || loading || !scopedCanFly" @input="validate"/>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-subheader>Route</v-subheader>
              <v-divider/>
              <v-textarea v-model="activeSortie.route" :disabled="isReadOnly || loading || !scopedCanFly" outlined
                          placeholder="Nature of flight. If cross country, provide details of route"
                          :rules="[(v)=> !!v || 'Required']" @input="validate"/>
            </v-flex>
            <v-spacer/>
            <v-flex xs12 sm9 md9>
              <div class="caption">Destination Aerodrome</div>
              <div>
                <airport-picker label="Enter Destination Aerodrome" v-model="activeSortie.pointOfArrivalName"
                                v-on:updateAirport="updateAirportDetails($event,'arrival')"
                                :default="loggedInPerson.flyingData.homeAirfield"
                                :disabled="isReadOnly || loading || !scopedCanFly" :show-favourite="!isReadOnly" @input="validate"/>
              </div>
            </v-flex>
            <v-spacer/>
            <v-flex xs12 sm12 md12 v-show="!activeSortie.captainDeclaration">
              <div>
                <duration-selector v-model="activeSortie.eET" :disabled="isReadOnly || loading || !scopedCanFly" :readonly="isReadOnly"
                                   label="Estimated Enroute Time (EET)" @input="validate"/>
              </div>
            </v-flex>
            <v-spacer/>
            <v-flex xs12 sm12>
              <v-subheader>Crew & Passengers</v-subheader>
              <v-divider/>
              <sortie-crew-list :loading="loading" :readonly="isReadOnly" :value.sync="activeSortie.crewList"
                                :disabled="isReadOnly || loading || !scopedCanFly" @input="validate"/>
            </v-flex>
            <v-spacer/>
          </v-layout>
        </v-container>
        <v-divider class="my-2"/>
        <div class="my-2 caption">Actions:</div>
        <div class="text-body-1">
          <app-btn @click="submit"
                   :disabled="loading || !scopedCanFly || isReadOnly || !formValid">
            <v-icon left>
              mdi-{{ activeSortie.hasOwnProperty('_id') ? 'update' : 'content-save' }}
            </v-icon>
            {{ activeSortie.hasOwnProperty('_id') ? 'Update' : 'Save' }}
          </app-btn>
        </div>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import {mapState, mapActions} from "vuex";
import AppBtn from "@/components/app/Btn";
import {SortieHttp} from "@/http/SortieHttp";
import AppDatePicker from "@/components/app/DatePicker";
import AppTimePicker from "@/components/app/TimePicker";
import AirportPicker from "@/components/shared/AirportPicker";
import AircraftPicker from "@/components/shared/AircraftPicker";
import SortieCrewList from "@/components/shared/SortieCrewList";
import DurationSelector from "@/components/shared/DurationSelector";
import moment from "moment";
import GroupPicker from "@/components/shared/GroupPicker";
import {format, getUnixTime, isSameDay} from "date-fns";
import {getGroupName} from "@/util/helpers";

export default {
  name: "CreateSortieView",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GroupPicker,
    DurationSelector,
    SortieCrewList,
    AircraftPicker,
    AirportPicker,
    AppTimePicker,
    AppDatePicker,
    AppBtn,
  },
  data() {
    return {
      today: format(new Date(), 'yyyy-MM-dd'),
      isReadOnly: false,
      showAircraftSelector: true,
      showPointOfDepartureSelector: true,
      showPointOfArrivalSelector: true,
      sortieObject: {
        date: format(new Date(), 'yyyy-MM-dd'),
        eTD: '',
        eTA: null,
        eET: 60,
        crewList: [],
        group: null,
        owner: null,
        pointOfDepartureICAO: null,
        pointOfDepartureName: null,
        pointOfArrivalName: null,
        pointOfArrivalICAO: null,
        route: null
      },
      localDate: null,
      localEtd: null,
      rules: {
        required: value => !!value || 'Required.',
      }
    }
  },
  computed: {
    ...mapState("sorties", ["activeSortie"]),
    ...mapState("aircraft", ["aircraft"]),
    ...mapState("airport", ["airport"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("roles", ["aircrewRoles"]),
    ...mapState("person", ["persons", "loggedInPerson"]),
    ...mapState("user", ["flyingStatus", "groupMembership","accountRoles"]),
    ...mapState("group", ["groups", "activeGroup"]),

    isSortieManager() {
      const userRoles = this.accountRoles.map(g => g.name)
      return userRoles.includes('rootAdmin') || userRoles.includes('manageSorties')
    },

    scopedCanFly() {
      let group = this.flyingStatus.find(g => g.groupId == this.activeSortie.group)
      return this.activeSortie.group ? group.canFly : false;
    },

    scopedGroups() {
      const ids = this.groupMembership.map(g => g._id)
      return this.groups.filter(g => ids.includes(g._id))
    },

    flightTypeItems() {
      return this.activeGroup ? this.activeGroup.flightTypes.filter(flightType => flightType.active) : []
    },

    minTime() {
      // return checkIsSameDay(this.activeSortie.date) ? formatTimestamp(this.activeSortie.date,'HH:mm:ss'):null
      const now = new Date()
      if (isSameDay(now, new Date(this.activeSortie.date))) {
        return now.toTimeString()
      }
      return null
    },
    formValid() {
      return this.activeSortie.date &&
          this.activeSortie.registration &&
          this.activeSortie.route &&
          this.activeSortie.pointOfDepartureName &&
          this.activeSortie.pointOfArrivalName &&
          this.activeSortie.flightType
    },
    hasMinCrew() {
      return this.activeSortie.crewList.length >= 1 ? 'true' : 'false'

    },
    // aircraftType() {
    //   if (this.activeSortie.registration == undefined) {
    //     return ''
    //   }
    //   return this.aircraft.find(a => a.registration === this.activeSortie.registration).aircraftType.designator
    // },


  },
  methods: {
    ...mapActions("roles", ["setAircrewRoles"]),
    ...mapActions("aircraft", ["setAircraft"]),
    ...mapActions("sorties", ["setActiveSortie", "getSortie", "setSorties"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("app", ["setToast", "setLoading"]),
    ...mapActions("group", ["setActiveGroup"]),

    changeTailNumber(aircraft){
      if (aircraft === undefined) return
      this.activeSortie.tailNumber = aircraft.tailNumber
    },

    getGroupName(id, style) {
      return getGroupName(this.groups, id, style)
    },
    validate() {
      this.$refs.form.validate()
    },

    async submit() {
      if (!this.$refs.form.validate()) return
      await this.setLoading(true)
      if (this.activeSortie.date &&
          this.activeSortie.registration &&
          this.activeSortie.pointOfDepartureName &&
          this.activeSortie.pointOfArrivalName) {
        try {
          await this.setLoading(true)
          //etd as Timestamp for API

          this.activeSortie.tzOffset = moment().utcOffset() * 60
          this.localDate = this.activeSortie.date


          this.activeSortie.eTD = getUnixTime(new Date(this.activeSortie.date + ' ' + this.localEtd)) // Timestamp in Local

          if (this.activeSortie._id) {
            await SortieHttp.updateSortie(this.activeSortie)
          } else {
            await SortieHttp.createNewSortie(this.activeSortie)
          }
          this.$emit('refreshSorties')
          await this.setActiveSortie(null)
          this.setToast({color: 'success', text: 'New Sortie added successfully', show: true});
          await this.setLoading(false)
          await this.$router.replace('/sorties/upcoming');

        } catch (err) {
          if (err.response) {
            console.log(err.response.data)

          } else {
            console.log(err)
          }
        } finally {
          // this.activeSortie.date = this.localDate
          // this.activeSortie.eTD = this.localEtd
          await this.setLoading(false)
        }
      } else {
        await this.setLoading(false)
      }
    },

    async updateGroupName() {
      const selectedGroup = this.groups.find(g => g._id === this.activeSortie.group)
      await this.setActiveGroup(selectedGroup)
      this.activeSortie.groupName = selectedGroup.name
      await this.updateGroupMemberList(this.activeSortie.group)
      this.validate()
    },

    async updateGroupMemberList(groupId) {
      await this.setPersons(groupId)
    },
    isUser(id) {
      return id === this.loggedInUser._id;
    },
    //
    addCrewMember() {
      this.activeSortie.crewList.push({
        person: '',
        role: ''
      })
    },
    //
    removeCrewMember(item) {
      const index = this.activeSortie.crewList.indexOf(item);
      this.activeSortie.crewList.splice(index, 1);
    },
    //
    formatTimestamp(timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    },
    //
    updateAirportDetails(airport, place) {
      if (place === 'departure') {
        this.activeSortie.pointOfDepartureId = airport._id;
        this.activeSortie.pointOfDepartureICAO = airport.ident;
      }
      if (place === 'arrival') {
        this.activeSortie.pointOfArrivalId = airport._id;
        this.activeSortie.pointOfArrivalICAO = airport.ident;
      }
    }
  },
  async created() {
    await this.setLoading(true)
    // await this.setAircraft()
    // await this.setAircrewRoles()
    await this.setPersons(this.groupMembership[0]._id)

    if (this.$route.params.id) {
      await this.getSortie(this.$route.params.id)
      this.localEtd = this.formatTimestamp(this.activeSortie.eTD.localTimestamp)
      this.isReadOnly = true
      await this.setLoading(false)
    } else {
      this.localEtd = moment().add(1, 'hour').format('HH:mm').toString()
      const person = this.persons.find(p => p._id === this.loggedInPerson._id)
      const role = this.aircrewRoles.find(r => r.name === 'Pilot in Command')
      this.sortieObject.crewList.push({
        personId: person._id,
        person: person.name,
        role: role.name,
        isPAX: false
      })
      this.sortieObject.group = this.groupMembership[0]._id
      this.sortieObject.groupName = this.groupMembership[0].name
      this.sortieObject.owner = this.loggedInUser._id

      const selectedGroup = this.groups.find(g => g._id === this.groupMembership[0]._id)
      await this.setActiveGroup(selectedGroup)
      await this.setActiveSortie(this.sortieObject)
      this.isReadOnly = false
      await this.setLoading(false)
    }

  },

  async beforeDestroy() {
    await this.setActiveSortie(null);
    await this.setLoading(false)
  }
}
</script>

<style scoped>

</style>